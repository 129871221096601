<template>
  <div v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S' && $store.state.fastPermissoes.edita_usuarios == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Gerenciamento de usuários</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">usuários</span>
                </h1>
                <img v-lazy="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div v-if="fastUsuarioRestricoes.administradores">
                  <div class="row"> 
                    <div class="col-12 mb-4">
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Lista de Administradores ({{ fastUsuariosAdministradoresFiltro.length }})
                              </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="showModal('modalConvidarAdministradores')"
                              >
                                <small>+ Cadastrar Administradores</small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Filtrar por nome ou email"
                                @keyup="filtraAdministradores"
                              >
                            </div> 
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th />
                                    <th>
                                      <small class="font-weight-bold">Nome</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Email</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Aceito</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Convidado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Usado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Permissões</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                    <th class="align-middle text-center">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastUsuariosAdministradoresFiltro.length">
                                  <tr
                                    v-for="(u, index) in pageOfAdministradores"
                                    :key="u.id_usuario"
                                  >
                                    <td>
                                      <small>{{ index + 1 }}</small>
                                    </td>
                                    <td>
                                      <small>{{ u.nome_usuario == "null null" ? "" : u.nome_usuario }}</small>
                                    </td>
                                    <td>
                                      <small>{{ u.email }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="u.id_admin_pai == 0"
                                        class="badge badge-success"
                                      >Admin pai</small>
                                      <small
                                        v-else-if="u.aceito == 'S'"
                                        class="badge badge-success"
                                      >sim</small>
                                      <small
                                        v-else
                                        class="badge badge-danger"
                                      >não</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_convite_envio">{{ new Date(u.data_convite_envio).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_aceite">{{ new Date(u.data_aceite).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <span
                                        v-show="!u.exibir_permissoes"
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                        @click="u.exibir_permissoes = true"
                                      >
                                        <small>Exibir</small>
                                      </span>
                                      <span
                                        v-show="u.exibir_permissoes"
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                        @click="u.exibir_permissoes = false"
                                      >
                                        <small>Ocultar</small>
                                      </span>
                                      <ul
                                        v-show="u.exibir_permissoes"
                                        class="list-group mt-2"
                                      >
                                        <li
                                          v-if="u.edita_admins == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita admins</small>
                                        </li>
                                        <li
                                          v-if="u.edita_alunos == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita alunos</small>
                                        </li>
                                        <li
                                          v-if="u.edita_cursos == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita cursos</small>
                                        </li>
                                        <li
                                          v-if="u.edita_financeiro == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita financeiro</small>
                                        </li>
                                        <li
                                          v-if="u.edita_matriz == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita matriz</small>
                                        </li>
                                        <li
                                          v-if="u.edita_plataforma == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita plataforma</small>
                                        </li>
                                        <li
                                          v-if="u.edita_professores == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita professores</small>
                                        </li>
                                        <li
                                          v-if="u.edita_usuarios == 'S'"
                                          class="list-group-item text-success pt-0 pb-0"
                                        >
                                          <small>Edita usuários</small>
                                        </li>
                                      </ul>
                                    </td>
                                    <td class="text-center">
                                      <button
                                        v-if="fastUsuarioLogado.id_usuario != u.id_usuario"
                                        class="btn btn-sm btn-warning pt-0 pb-0 mr-2"
                                        @click="exibeModalUsuarioReenviar(u)"
                                      >
                                        <small class="text-white">Reenviar convite</small>
                                      </button>
                                      <button
                                        v-if="fastUsuarioLogado.id_usuario != u.id_usuario"
                                        class="btn btn-sm btn-warning pt-0 pb-0 mr-2"
                                        @click="exibeModalEditarPermissoes(u)"
                                      >
                                        <small class="text-white">Editar permissões</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="exibeModalUsuarioExcluir(u)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </td>
                                    <td class="align-middle">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastAdministradoresLoading">
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="text-center"
                                    >
                                      Carregando Administradores...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr class="text-center">
                                    <td colspan="9">
                                      Nenhum administrador encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastUsuariosAdministradoresFiltro"
                                @changePage="pageOfAdministradores = $event"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="fastUsuarioRestricoes.professores">
                  <div class="row"> 
                    <div class="col-12 mb-4">
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Lista de Professores ({{ fastUsuariosProfessoresFiltro.length }})
                              </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="showModal('modalConvidarProfessores')"
                              >
                                <small> + Cadastrar professor</small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Filtrar por nome ou email"
                                @keyup="filtraProfessores"
                              >
                            </div>
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th />
                                    <th>
                                      <small class="font-weight-bold">Nome</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Email</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Aceito</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Convidado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Usado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                    <th class="align-middle text-center">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastUsuariosProfessoresFiltro.length">
                                  <tr
                                    v-for="u in pageOfProfessores"
                                    :key="u.id_usuario"
                                  >
                                    <td>
                                      <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(u.image_prof) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                    </td>
                                    <td>
                                      <small v-if="u.id_usuario">{{ u.nome_usuario }}</small>
                                      <small v-else>{{ u.first_name }} {{ u.last_name }}</small>
                                    </td>
                                    <td>
                                      <small>{{ u.email ? u.email : u.email_prof }}</small>
                                    </td>
                                    <td class="text-center">
                                      <span v-if="u.id_usuario">
                                        <small
                                          v-if="u.aceito == 'S'"
                                          class="badge badge-success"
                                        >sim</small>
                                        <small
                                          v-else
                                          class="badge badge-danger"
                                        >não</small>
                                      </span>
                                      <small
                                        v-else
                                        class="text-danger"
                                      >Usuário sem conta</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_convite_envio">{{ new Date(u.data_convite_envio).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_aceite">{{ new Date(u.data_aceite).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <button
                                        class="btn btn-sm btn-warning pt-0 pb-0 mr-2"
                                        @click="exibeModalUsuarioReenviar(u)"
                                      >
                                        <small class="text-white">Reenviar convite</small>
                                      </button>
                                      <button
                                        v-if="u.id_usuario"
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="exibeModalUsuarioExcluir(u)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="exibeModalProfessorExcluir(u)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </td>
                                    <td class="align-middle">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastProfessoresLoading">
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="text-center"
                                    >
                                      Carregando Professores...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr class="text-center">
                                    <td colspan="8">
                                      Nenhum professor encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastUsuariosProfessoresFiltro"
                                @changePage="pageOfProfessores = $event"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="fastUsuarioRestricoes.alunos">
                  <div class="row"> 
                    <div class="col-12 mb-4">
                      <div class="card shadow mb-4">
                        <div class="card-header py-3">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <h6 class="m-0 font-weight-bold text-primary">
                                Lista de Alunos ({{ fastUsuariosAlunosFiltro.length }})
                              </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                              <button
                                class="btn btn-sm btn-primary"
                                @click.prevent="showModal('modalConvidarAlunos')"
                              >
                                <small> + Cadastrar aluno</small>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">  
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Filtrar por nome ou email"
                                @keyup="filtraAlunos"
                              >
                            </div> 
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th />
                                    <th>
                                      <small class="font-weight-bold">Nome</small>
                                    </th>
                                    <th>
                                      <small class="font-weight-bold">Email</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Aceito</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ativado</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Convidado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Usado em</small>
                                    </th>
                                    <th class="text-center">
                                      <small class="font-weight-bold">Ações</small>
                                    </th>
                                    <th class="align-middle text-center">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastUsuariosAlunosFiltro.length">
                                  <tr
                                    v-for="(u, index) in pageOfAlunos"
                                    :key="u.id_usuario"
                                  >
                                    <td>
                                      <small>{{ index + 1 }}</small>
                                    </td>
                                    <td>
                                      <small>{{ u.nome_usuario == "null null" ? "" : u.nome_usuario }}</small>
                                    </td>
                                    <td>
                                      <small>{{ u.email }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="u.aceito == 'S' && u.ativo == 'S'"
                                        class="badge badge-success mr-2"
                                      >sim</small>
                                      <small
                                        v-else-if="u.aceito == 'S' && u.ativo == 'N'"
                                        class="badge badge-danger mr-2"
                                      >desativado</small>
                                      <small
                                        v-else
                                        class="badge badge-danger mr-2"
                                      >não</small>
                                    </td>
                                    <td class="text-center">
                                      <small
                                        v-if="u.ativo == 'S'"
                                        class="badge badge-success mr-2"
                                      >sim</small>
                                      <small
                                        v-else
                                        class="badge badge-danger mr-2"
                                      >Não</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_convite_envio">{{ new Date(u.data_convite_envio).toLocaleDateString() }}</small>
                                    </td>
                                    <td class="text-center">
                                      <small v-if="u.data_aceite">{{ new Date(u.data_aceite).toLocaleDateString() }}</small>
                                    </td>

                                    <td class="text-center">
                                      <button
                                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                        @click="exibeModalMeusCursos(u)"
                                      >
                                        <small class="text-white">+ Curso</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                        @click="exibeModalEstatisticasUsuario(u)"
                                      >
                                        <small class="text-white">Estatísticas</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-warning pt-0 pb-0 mr-2"
                                        @click="exibeModalUsuarioReenviar(u)"
                                      >
                                        <small class="text-white">Reenviar convite</small>
                                      </button>
                                      <button
                                        v-if="u.ativo == 'S'"
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="exibeModalUsuarioDesativar(u)"
                                      >
                                        <small>Desativar</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                        @click="exibeModalUsuarioAtivar(u)"
                                      >
                                        <small>Ativar</small>
                                      </button>
                                      <button
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="exibeModalUsuarioExcluir(u)"
                                      >
                                        <small>Excluir</small>
                                      </button>
                                    </td>
                                    <td class="align-middle text-center">
                                      <input
                                        type="checkbox"
                                        class="form-control"
                                        style="width: 15px; height: 15px"
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastAlunosLoading">
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="text-center"
                                    >
                                      Carregando Alunos...
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr class="text-center">
                                    <td colspan="9">
                                      Nenhum aluno encontrado
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastUsuariosAlunosFiltro"
                                @changePage="pageOfAlunos = $event"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalConvidarAdministradores"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Convidar administradores</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConvidarAdministradores')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <textarea
              v-model="administradoresConvite.emails"
              class="form-control"
              rows="3"
              type="text"
              placeholder
            />
            <small class="text-muted">Informe um email por linha</small>
            <br>
            <div class="fd-app-curso-aulas">
              <h5>Permissões</h5>
              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row">
                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita cursos</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_cursos"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_cursos)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita usuários</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_usuarios"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_usuarios)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita administradores</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_admins"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_admins)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita professores</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_professores"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_professores)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita alunos</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_alunos"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_alunos)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita plataforma</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_plataforma"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_plataforma)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita matriz</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_matriz"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_matriz)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita financeiro</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="administradoresConvite.permissoes.edita_financeiro"
                          type="checkbox"
                          @change="checkPermissoesAdmin(administradoresConvite.permissoes.edita_financeiro)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <small class="text-info">{{ statusConvite }}</small>
              </div>
              <div class="col-12 mt-2">
                <button
                  class="btn btn-primary"
                  @click="convidarUsuarios('administradores')"
                >
                  <small>Convidar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarPermissoes"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar permissões</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPermissoes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div
              v-if="fastUsuarioEditar.id_usuario"
              class="fd-app-curso-aulas"
            >
              <div class="text-center">
                <h5>Permissões de {{ fastUsuarioEditar.nome_usuario }}</h5>
              </div>

              <div class="fd-app-curso-aulas-list mt-0">
                <div class="fd-app-curso-aulas-list-item shadow-none border-0 row">
                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita cursos</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_cursos"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_cursos)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita usuários</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_usuarios"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_usuarios)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita administradores</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_admins"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_admins)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita professores</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_professores"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_professores)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita alunos</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_alunos"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_alunos)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita plataforma</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_plataforma"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_plataforma)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita matriz</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_matriz"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_matriz)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                    <div class="col-12 text-center mb-1">
                      <span>Edita financeiro</span>
                    </div>
                    <div class="col-12 text-center">
                      <label class="switch">
                        <input
                          v-model="fastUsuarioEditar.edita_financeiro"
                          type="checkbox"
                          @change="checkPermissoesAdmin(fastUsuarioEditar.edita_financeiro)"
                        >
                        <span class="slider round" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <small class="text-info">{{ statusConvite }}</small>
              </div>
              <div class="col-12 mt-2">
                <button
                  class="btn btn-primary"
                  @click="alteraUsuario('P')"
                >
                  <small>Alterar permissões</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalConvidarProfessores"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Convidar professores</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConvidarProfessores')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome</label>
              <input
                v-model="professoresConvite.first_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Sobrenome</label>
              <input
                v-model="professoresConvite.last_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Email</label>
              <input
                v-model="professoresConvite.email"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Formação</label>
              <select
                v-model="professoresConvite.licenciatura"
                class="form-control"
              >
                <option value="0">
                  Nenhuma
                </option>
                <option value="1">
                  Graduação
                </option>
                <option value="2">
                  Especialização
                </option>
                <option value="3">
                  Mestrado
                </option>
                <option value="4">
                  Doutorado
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label for>Imagem do professor</label>
              <vue-cropper @image="professoresConvite.image = $event" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="convidarUsuarios('professores')"
                >
                  <small>Convidar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalConvidarProfessoresSemConta"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cadastrar professor sem conta</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConvidarProfessoresSemConta')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome</label>
              <input
                v-model="professoresConvite.first_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Sobrenome</label>
              <input
                v-model="professoresConvite.last_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Email</label>
              <input
                v-model="professoresConvite.email"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Formação</label>
              <select
                v-model="professoresConvite.licenciatura"
                class="form-control"
              >
                <option value="0">
                  Nenhuma
                </option>
                <option value="1">
                  Graduação
                </option>
                <option value="2">
                  Especialização
                </option>
                <option value="3">
                  Mestrado
                </option>
                <option value="4">
                  Doutorado
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label for>Imagem do professor</label>
              <vue-cropper @image="professoresConvite.image = $event" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="cadastrarProfessorSemConta()"
                >
                  <small>Cadastrar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalConvidarAlunos"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Convidar alunos</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConvidarAlunos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <textarea
                v-model="alunosConvite.emails"
                class="form-control"
                rows="3"
                type="text"
                placeholder
              />
              <small class="text-muted">Informe um email por linha</small>
            </div>

            <div class="col-12 mt-2">
              <div class="mb-2">
                <small class="text-info">{{ statusConvite }}</small>
              </div>
              <div>
                <button
                  class="btn btn-primary"
                  @click="convidarUsuarios('alunos')"
                >
                  <small>Convidar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUsuarioExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUsuarioDesativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Desativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioDesativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioDesativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUsuarioAtivar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Ativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioAtivar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioAtivar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalProfessorExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir professor?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalProfessorExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaProfessorExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUsuarioReenviar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Reenviar convite?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioReenviar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  v-if="!fastAcao"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="alteraUsuario('R')"
                >
                  Confirmar
                </button>
                <div
                  v-if="fastLinkConvite"
                  class="text-center"
                >
                  <h6>Link do convite</h6>
                  <div class="input-group">
                    <input
                      id="fastLinkConvite"
                      v-model="fastLinkConvite"
                      type="text"
                      class="form-control"
                      placeholder="text"
                      readonly
                    >
                    <div class="input-group-btn">
                      <button
                        class="btn btn-default rounded-0"
                        @click="copyTestingCode()"
                      >
                        <i class="far fa-copy" />
                      </button>
                    </div>
                  </div>
                  <h6
                    class="text-info mt-4"
                    v-text="fastLinkConviteMsg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEstatisticasUsuario"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Estatísticas do aluno</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEstatisticasUsuario')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-2">
              <button
                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                @click="geraRelatorioAlunoExcel(fastUsuarioCurso)"
              >
                <small
                  v-if="!fastCarregandoAcao"
                  class="text-white"
                >Gerar relatório de avaliações</small>
                <small v-else><b-icon
                  icon="gear-fill"
                  animation="spin"
                /> Carregando</small>
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Curso
                    </th>
                    <th scope="col">
                      Data de início
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Realizou
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Aulas realizadas
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfFastUsuarioCurso.length">
                  <tr
                    v-for="(curso, index) in pageOfFastUsuarioCurso"
                    :key="curso.id_usuario_curso"
                  >
                    <td class="align-middle">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ curso.nome_curso }}
                    </td>
                    <td class="align-middle">
                      {{ new Date(curso.data_inicio_curso).toLocaleDateString() }}
                    </td>
                    <td class="align-middle text-center">
                      <small
                        v-if="curso.status == 'E'"
                        class="text-warning"
                      >Em execução</small>
                      <small
                        v-else-if="curso.status == 'F'"
                        class="text-success"
                      >Finalizado</small>
                    </td>
                    <td class="align-middle text-center">
                      <span
                        v-if="curso.count_curso"
                        class="btn btn-sm btn-success"
                      >
                        <small>{{ curso.count_curso }} veze(s)</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger"
                      >
                        <small />
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <small class="btn btn-sm btn-warning text-white pr-3 pl-3">{{ curso.aulas.length }}</small>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="6"
                    >
                      Nenhuma estatísticas encontrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastUsuarioCurso"
                @changePage="pageOfFastUsuarioCurso = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalMeusCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div
        v-if="fastUsuarioEditar.id_usuario"
        class="row p-4"
      >
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Aluno - {{ fastUsuarioEditar.nome_usuario ? fastUsuarioEditar.nome_usuario : fastUsuarioEditar.email }}</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <h6>Lista de cursos</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraCursos"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Curso
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfMeusCursos.length">
                  <tr
                    v-for="(curso, index) in pageOfMeusCursos"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ curso.nome_curso }}
                    </td>
                    <td class="align-middle text-center">
                      <span
                        v-if="!verificaUsuarioInseridoCurso(curso.id_curso).length"
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                        @click="insereUsuarioRestrito(fastUsuarioEditar.id_usuario, curso.id_curso)"
                      >
                        <small>+ Adicionar aluno ao curso</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger text-white pt-0 pb-0"
                        @click="excluiUsuarioRestrito(fastUsuarioEditar.id_usuario, curso.id_curso)"
                      >
                        <small>- Remover aluno do curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="3"
                    >
                      Nenhuma curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="10"
                :items="fastMeusCursosFiltro"
                @changePage="pageOfMeusCursos = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import VueCropper from "../components/ImageCropperUsuario";
export default {
  name: "HomeInternoAdminUsuarios",
  components: {
    Pagination,
    VueCropper,
  },
  mixins: [methods],
  data: function() {
    return {
      fastAlunosLoading: true,
      fastProfessoresLoading: true,
      fastAdministradoresLoading: true,
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastUsuarioLogado: {
        id_usuario: this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario,
      },
      // Administradores
      fastUsuariosAdministradores: [],
      fastUsuariosAdministradoresFiltro: [],
      pageOfAdministradores: [],
      administradoresConvite: {
        emails: "",
        permissoes: {
          administrador: "S",
          edita_cursos: false,
          edita_usuarios: false,
          edita_administradores: false,
          edita_professores: false,
          edita_alunos: false,
          edita_plataforma: false,
          edita_matriz: false,
          edita_financeiro: false,
          edita_admins: false,
          professor: "N",
        },
      },
      // Professores
      fastUsuariosProfessores: [],
      fastUsuariosProfessoresFiltro: [],
      pageOfProfessores: [],
      professoresConvite: {
        id_usuario: "",
        id_professor_concurseiro: 0,
        id_professor: 0,
        first_name: "",
        last_name: "",
        licenciatura: "0",
        image: "https://toussaint.concurseiro.com/css/images/user.png",
        email: "",
        permissoes: {
          administrador: "N",
          edita_cursos: false,
          edita_usuarios: false,
          edita_administradores: false,
          edita_professores: false,
          edita_alunos: false,
          edita_plataforma: false,
          edita_matriz: false,
          edita_financeiro: false,
          edita_admins: false,
          professor: "S",
        },
      },
      // Alunos
      fastUsuariosAlunos: [],
      fastUsuariosAlunosFiltro: [],
      pageOfAlunos: [],
      alunosConvite: {
        emails: "",
        permissoes: {
          administrador: "N",
          edita_cursos: false,
          edita_usuarios: false,
          edita_administradores: false,
          edita_professores: false,
          edita_alunos: false,
          edita_plataforma: false,
          edita_matriz: false,
          edita_financeiro: false,
          edita_admins: false,
          professor: "N",
        },
      },
      // Status
      statusConvite: "",
      // Exclusão
      fastUsuarioExcluir: [],
      fastProfessorSemContaExcluir: [],
      fastUsuarioEditar: {},
      // Estatiscas do usuario
      fastUsuarioCurso: [],
      pageOfFastUsuarioCurso: [],
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Ações
      fastAcao: false,
      fastLinkConvite: "",
      fastLinkConviteMsg: "",
      // Restrições
      fastUsuarioRestricoes: {
        administradores: false,
        professores: false,
        alunos: false,
      },
      // Cursos
      fastMeusCursos: [],
      pageOfMeusCursos: [],
      fastMeusCursosFiltro: [],
      usuariosRestritos: [],
      // Loading da ação
      fastCarregandoAcao: false,
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getDependenciasVisaoAdmin(this.$route.params.id_plataforma)
        .then(() => {
          if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S" && this.$store.state.fastPermissoes.edita_usuarios == "S") {
            if (this.getUrlParameter("Administradores") && this.$store.state.fastPermissoes.edita_admins == "S") {
              this.fastUsuarioRestricoes.administradores = true;
              this.getFastUsuariosAdministradores();
            } else if (this.getUrlParameter("Professores") && this.$store.state.fastPermissoes.edita_professores == "S") {
              this.fastUsuarioRestricoes.professores = true;
              this.getFastUsuariosProfessores();
            } else if (this.getUrlParameter("Alunos") && this.$store.state.fastPermissoes.edita_alunos == "S") {
              this.fastUsuarioRestricoes.alunos = true;
              this.getFastUsuariosAlunos();
              this.getFastCursos();
            } else {
              if (this.$store.state.fastPermissoes.edita_admins == "S") {
                this.fastUsuarioRestricoes.administradores = true;
                this.getFastUsuariosAdministradores();
              }
              if (this.$store.state.fastPermissoes.edita_professores == "S") {
                this.fastUsuarioRestricoes.professores = true;
                this.getFastUsuariosProfessores();
              }
              if (this.$store.state.fastPermissoes.edita_alunos == "S") {
                this.fastUsuarioRestricoes.alunos = true;
                this.getFastUsuariosAlunos();
                this.getFastCursos();
              }
            }
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastUsuariosAdministradores() {
      this.statusConvite = "";
      this.fastUsuariosAdministradores = [];
      this.fastUsuariosAdministradoresFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_usuarios_plataforma?id_plataforma=" + this.$route.params.id_plataforma + "&administrador=S&professor=N", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        console.log("Administradores", json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((u) => {
            if (!u.nome_usuario) u.nome_usuario = "Não cadastrado";

            u.exibir_permissoes = false;
          });
          this.fastUsuariosAdministradores = obj;
          this.fastUsuariosAdministradoresFiltro = obj;
          this.fastAdministradoresLoading = false
        }
        //console.log("fastUsuariosAdministradoresFiltro", this.fastUsuariosAdministradoresFiltro);
      } catch (e) {
        console.log("Erro", e);
        
          this.fastAdministradoresLoading = false
      }
    },
    filtraAdministradores(e) {
      let text = e.target.value;
      this.fastUsuariosAdministradoresFiltro = this.fastUsuariosAdministradores.filter((e) => {
        if (e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1") return e;
      });
    },
    filtraProfessores(e) {
      let text = e.target.value;
      this.fastUsuariosProfessoresFiltro = this.fastUsuariosProfessores.filter((e) => {
        if (e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email_prof.toLowerCase().indexOf(text.toLowerCase()) != "-1") return e;
      });
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastUsuariosAlunosFiltro = this.fastUsuariosAlunos.filter((e) => {
        return e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    filtraCursos(e) {
      let text = e.target.value;
      this.fastMeusCursosFiltro = this.fastMeusCursos.filter((e) => {
        return e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    async getFastUsuariosProfessores() {
      this.statusConvite = "";
      this.fastUsuariosProfessores = [];
      this.fastUsuariosProfessoresFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/lista_semcom_usuario?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log("Professores", json);
        if (obj.length > 0) {
          this.fastUsuariosProfessores = obj;
          this.fastUsuariosProfessoresFiltro = obj;
          this.fastProfessoresLoading = false
        }
        //this.getFastUsuariosProfessoresSemConta();
      } catch (e) {
        console.log("Erro", e);
        this.fastProfessoresLoading = false
      }
    },
    async getFastUsuariosProfessoresSemConta() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/lista_sem_usuario?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.fastUsuariosProfessores = this.fastUsuariosProfessores.concat(obj);
          this.fastUsuariosProfessoresFiltro = this.fastUsuariosProfessoresFiltro.concat(obj);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastUsuariosAlunos() {
      this.statusConvite = "";
      this.fastUsuariosAlunos = [];
      this.fastUsuariosAlunosFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_usuarios_plataforma?id_plataforma=" + this.$route.params.id_plataforma + "&administrador=N&professor=N", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        //console.log("Alunos", json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((u) => {
            if (!u.nome_usuario) u.nome_usuario = "Não cadastrado";
          });
          this.fastUsuariosAlunos = obj;
          this.fastUsuariosAlunosFiltro = obj;
          this.fastAlunosLoading = false;
        }
      } catch (e) {
        console.log("Erro", e);
        this.fastAlunosLoading = false;
      }
    },
    checkPermissoesAdmin(check) {
      check = !check;
    },
    // Novo convite
    convidarUsuariosNovo(tipo) {
      let model = {
        fast_plataforma_usuario: {
          id_plataforma: 0,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          email: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        }
      }
      if (tipo == "administradores") {
        this.separaEmailsCadastroNovo(this.administradoresConvite);
      } else if (tipo == "professores") {
        this.professoresConvite.id_plataforma = this.$route.params.id_plataforma;
        if (!this.professoresConvite.image) this.professoresConvite.image = "https://toussaint.concurseiro.com/css/images/user.png";
        this.professoresConvite.emails = this.professoresConvite.email;
        this.professoresConvite.nome_usuario = this.professoresConvite.first_name + " " + this.professoresConvite.last_name;

        let erros = [];
        if (!this.professoresConvite.first_name) erro.push("O nome não pode ficar em branco");
        if (!this.professoresConvite.last_name) erro.push("O sobrenome não pode ficar em branco");
        if (!this.professoresConvite.email) erro.push("O email não pode ficar em branco");

        if (erros.length) {
          erros.forEach((e) => {
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: e,
            });
          });
        } else {
          // Loading
          this.$store.state.fastCarregando = true;
          this.separaEmailsCadastroNovo(this.professoresConvite);
        }
      } else if (tipo == "alunos") {
        this.separaEmailsCadastroNovo(this.alunosConvite);
      }
    },
    separaEmailsCadastroNovo(objUsuario) {
      let first_name = objUsuario.first_name ? objUsuario.first_name : "";
      let last_name = objUsuario.last_name ? objUsuario.last_name : "";
      this.statusConvite = "Validando emails...";
      if (objUsuario.emails.length) {
        let listaEmails = objUsuario.emails.split("\n");
        listaEmails.map((e) => this.validateEmail(e));
        objUsuario.emails = listaEmails;
        if (objUsuario.emails.length) {
          let ultimo_email = objUsuario.emails[objUsuario.emails.length - 1];
          objUsuario.emails.forEach((e) => this.verificaConvite(e, ultimo_email, objUsuario.permissoes, first_name, last_name, ""));
        }
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Insira ao menos um email válido",
        });
      }
    },
    // 1º - Verifica tipo de usuário
    convidarUsuarios(tipo) {
      if (tipo == "administradores") {
        this.separaEmailsCadastro(this.administradoresConvite);
      } else if (tipo == "professores") {
        this.professoresConvite.id_plataforma = this.$route.params.id_plataforma;
        if (!this.professoresConvite.image) this.professoresConvite.image = "https://toussaint.concurseiro.com/css/images/user.png";
        this.professoresConvite.emails = this.professoresConvite.email;
        this.professoresConvite.nome_usuario = this.professoresConvite.first_name + " " + this.professoresConvite.last_name;

        let erros = [];
        if (!this.professoresConvite.first_name) erro.push("O nome não pode ficar em branco");
        if (!this.professoresConvite.last_name) erro.push("O sobrenome não pode ficar em branco");
        if (!this.professoresConvite.email) erro.push("O email não pode ficar em branco");

        if (erros.length) {
          erros.forEach((e) => {
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: e,
            });
          });
        } else {
          // Loading
          this.$store.state.fastCarregando = true;
          this.separaEmailsCadastro(this.professoresConvite);
        }
      } else if (tipo == "alunos") {
        this.separaEmailsCadastro(this.alunosConvite);
      }
    },
    // 2º - Separa a lista de email
    separaEmailsCadastro(objUsuario) {
      let first_name = objUsuario.first_name ? objUsuario.first_name : "";
      let last_name = objUsuario.last_name ? objUsuario.last_name : "";
      this.statusConvite = "Validando emails...";
      if (objUsuario.emails.length) {
        let listaEmails = objUsuario.emails.split("\n");
        listaEmails.map((e) => this.validateEmail(e));
        objUsuario.emails = listaEmails;
        if (objUsuario.emails.length) {
          let ultimo_email = objUsuario.emails[objUsuario.emails.length - 1];
          objUsuario.emails.forEach((e) => this.verificaConvite(e, ultimo_email, objUsuario.permissoes, first_name, last_name, ""));
        }
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Insira ao menos um email válido",
        });
      }
    },
    // 3º - Verifica se usuário já é cadastrado
    async verificaConvite(email, ultimo_email, permissoes, first_name, last_name, id_professor) {
      this.statusConvite = "Verificando email..." + email;
      this.$notify({
        group: "foo",
        type: "success",
        duration: 5000,
        speed: 1000,
        title: "Status",
        text: "Verificando email..." + email,
      });
      try {
        let resp = await fetch(settings.endApiConcurseiro + "api/concurseiro/questoes/verificaEmail?email=" + email, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length > 0) {
          this.statusConvite = "Email já cadastrado na base concurseiro: " + email;
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Email já cadastrado na base: " + email,
          });
          this.cadastraUsuarioPlataforma(obj[0].id_usuario, email, ultimo_email, permissoes, obj[0].first_name, obj[0].last_name, id_professor);
        } else {
          this.statusConvite = "Email não cadastrado: " + email;
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Email não cadastrado na base concurseiro: " + email + ". Cadastrando...",
          });
          this.cadastraUsuarioConcurseiro(email, ultimo_email, permissoes, first_name, last_name, id_professor);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // 4º - Cadastra usuário no concurseiro se não existir
    async cadastraUsuarioConcurseiro(email, ultimo_email, permissoes, first_name, last_name, id_professor) {
      let usuario = new Object();
      usuario.first_name = first_name;
      usuario.last_name = last_name;
      usuario.email = email;
      usuario.password = "";
      usuario.id_pessoa = "";

      try {
        let resp = await fetch(settings.endApiConcurseiro + "api/usuarios/cadastraemail", this.fastAjaxOptions("POST", JSON.stringify(usuario)));
        let json = await resp.json();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Email " + email + " cadastrado na base concurseiro com sucesso",
        });
        this.cadastraUsuarioPlataforma(json.id_usuario, email, ultimo_email, permissoes, first_name, last_name, id_professor);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // 5º - Cadastra usuário e envia email de convite
    async cadastraUsuarioPlataforma(id_usuario, email, ultimo_email, permissoes, first_name, last_name, id_professor) {
      let fast_plataforma_usuario = {
        id_usuario: id_usuario,
        email: email,
        id_plataforma: this.$route.params.id_plataforma,
        administrador: permissoes.administrador,
        edita_cursos: permissoes.edita_cursos == false ? "N" : "S",
        edita_usuarios: permissoes.edita_usuarios == false ? "N" : "S",
        edita_professores: permissoes.edita_professores == false ? "N" : "S",
        edita_plataforma: permissoes.edita_plataforma == false ? "N" : "S",
        edita_matriz: permissoes.edita_matriz == false ? "N" : "S",
        edita_financeiro: permissoes.edita_financeiro == false ? "N" : "S",
        edita_alunos: permissoes.edita_alunos == false ? "N" : "S",
        edita_admins: permissoes.edita_admins == false ? "N" : "S",
        professor: permissoes.professor,
        nome_usuario: first_name + " " + last_name,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/insere_usuario_convite", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log(json, obj);
        if (obj) {
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Convite enviado com sucesso para " + email,
          });

          if (this.administradoresConvite.emails) {
            if (this.administradoresConvite.emails.length) {
              this.administradoresConvite = {
                emails: "",
                permissoes: {
                  administrador: "S",
                  edita_cursos: false,
                  edita_usuarios: false,
                  edita_administradores: false,
                  edita_professores: false,
                  edita_alunos: false,
                  edita_plataforma: false,
                  edita_matriz: false,
                  edita_financeiro: false,
                  edita_admins: false,
                  professor: "N",
                },
              };
              this.getFastUsuariosAdministradores();
            }
          }
          if (this.professoresConvite.emails) {
            if (this.professoresConvite.emails.length) {
              this.professoresConvite.id_usuario = obj[0].id_usuario;
              this.atualizaUsuarioProfessor();
            }
          }
          if (this.alunosConvite.emails) {
            if (this.alunosConvite.emails.length) {
              this.alunosConvite = {
                emails: "",
                permissoes: {
                  administrador: "N",
                  edita_cursos: false,
                  edita_usuarios: false,
                  edita_administradores: false,
                  edita_professores: false,
                  edita_alunos: false,
                  edita_plataforma: false,
                  edita_matriz: false,
                  edita_financeiro: false,
                  edita_admins: false,
                  professor: "N",
                },
              };
              this.getFastUsuariosAlunos();
            }
          }
          this.hideModal("modalConvidarAdministradores");
          this.hideModal("modalConvidarAlunos");
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Erro ao cadastrar usuário na plataforma",
          });
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalUsuarioExcluir(usuario) {
      this.fastUsuarioExcluir = usuario;
      this.showModal("modalUsuarioExcluir");
    },
    async confirmaUsuarioExcluir() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/exclui_usuario", this.fastAjaxOptions("POST", JSON.stringify(this.fastUsuarioExcluir)));
        let json = await resp.json();
        let obj = Array.from(json);

        if (this.fastUsuarioExcluir.administrador == "S") this.getFastUsuariosAdministradores();
        else if (this.fastUsuarioExcluir.professor == "S") this.getFastUsuariosProfessores();
        else this.getFastUsuariosAlunos();

        this.fastUsuarioExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário excluído com sucesso",
        });
        this.hideModal("modalUsuarioExcluir");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalProfessorExcluir(usuario) {
      this.fastProfessorSemContaExcluir = usuario;
      this.showModal("modalProfessorExcluir");
    },
    async confirmaProfessorExcluir() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastProfessorSemContaExcluir)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.getFastUsuariosProfessores();

        this.fastProfessorSemContaExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Professor excluído com sucesso",
        });
        this.hideModal("modalProfessorExcluir");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async cadastrarProfessorSemConta() {
      this.professoresConvite.id_plataforma = this.$route.params.id_plataforma;
      if (!this.professoresConvite.image) this.professoresConvite.image = "https://toussaint.concurseiro.com/css/images/user.png";

      let erros = [];
      if (!this.professoresConvite.first_name) erro.push("O nome não pode ficar em branco");
      if (!this.professoresConvite.last_name) erro.push("O sobrenome não pode ficar em branco");
      if (!this.professoresConvite.email) erro.push("O email não pode ficar em branco");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: e,
          });
        });
      } else {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/insere", this.fastAjaxOptions("POST", JSON.stringify(this.professoresConvite)));
          let json = await resp.json();
          let obj = Array.from(json);

          this.getFastUsuariosProfessores();
          this.professoresConvite = {
            id_usuario: "",
            id_professor_concurseiro: 0,
            id_professor: 0,
            first_name: "",
            last_name: "",
            licenciatura: "0",
            image: "https://toussaint.concurseiro.com/css/images/user.png",
            emails: "",
            permissoes: {
              administrador: "N",
              edita_cursos: false,
              edita_usuarios: false,
              edita_administradores: false,
              edita_professores: false,
              edita_alunos: false,
              edita_plataforma: false,
              edita_matriz: false,
              edita_financeiro: false,
              edita_admins: false,
              professor: "S",
            },
          };
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Professor inserido com sucesso",
          });
          this.hideModal("modalConvidarProfessoresSemConta");
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async cadastrarProfessor() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/insere", this.fastAjaxOptions("POST", JSON.stringify(this.professoresConvite)));
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastUsuariosProfessores();
        // Loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        // Loading
        this.$store.state.fastCarregando = false;
      }
    },
    async atualizaUsuarioProfessor() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/insere", this.fastAjaxOptions("POST", JSON.stringify(this.professoresConvite)));
        let json = await resp.json();
        let obj = Array.from(json);
        // Loading
        this.$store.state.fastCarregando = false;
        this.hideModal("modalConvidarProfessores");
        this.professoresConvite = {
          id_usuario: "",
          id_professor_concurseiro: 0,
          id_professor: 0,
          first_name: "",
          last_name: "",
          licenciatura: "0",
          image: "https://toussaint.concurseiro.com/css/images/user.png",
          email: "",
          permissoes: {
            administrador: "N",
            edita_cursos: false,
            edita_usuarios: false,
            edita_administradores: false,
            edita_professores: false,
            edita_alunos: false,
            edita_plataforma: false,
            edita_matriz: false,
            edita_financeiro: false,
            edita_admins: false,
            professor: "S",
          },
        };
        this.getFastUsuariosProfessores();
      } catch (e) {
        console.log("Erro", e);
        // Loading
        this.$store.state.fastCarregando = false;
      }
    },
    exibeModalUsuarioReenviar(usuario) {
      this.fastUsuarioEditar = usuario;
      this.fastAcao = false;
      this.fastLinkConvite = "";
      this.fastLinkConviteMsg = "";
      this.showModal("modalUsuarioReenviar");
    },
    exibeModalEditarPermissoes(usuario) {
      this.fastUsuarioEditar = usuario;
      this.fastAcao = false;
      this.showModal("modalEditarPermissoes");
    },
    exibeModalMeusCursos(usuario) {
      this.fastUsuarioEditar = usuario;
      this.fastAcao = false;
      this.getUsuariosRestritos(usuario.id_usuario)
        .then((cursos) => {
          this.usuariosRestritos = cursos;
          this.showModal("modalMeusCursos");
        })
        .catch((e) => {
          console.log("getUsuariosRestritos = ERRO", e);
        });
    },
    verificaUsuarioInseridoCurso(id_curso) {
      return this.usuariosRestritos.filter((c) => c.id_curso == id_curso);
    },
    async alteraUsuario(acao) {
      this.fastLinkConvite = "";
      let mensagem_sucesso = "";
      let fast_plataforma_usuario = {};
      if (acao == "R") {
        fast_plataforma_usuario = {
          email: this.fastUsuarioEditar.email,
          administrador: this.fastUsuarioEditar.administrador,
          professor: this.fastUsuarioEditar.professor,
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          convite_enviado: "S",
          data_convite_reenvio: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          aceito: "N",
          data_aceite: null,
          ativo: "N",
          token: '1',
        };
        mensagem_sucesso = "Convite reenviado com sucesso";
      } else {
        //console.log("this.fastUsuarioEditar", this.fastUsuarioEditar);
        fast_plataforma_usuario = {
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          edita_cursos: this.fastUsuarioEditar.edita_cursos == false ? "N" : "S",
          edita_usuarios: this.fastUsuarioEditar.edita_usuarios == false ? "N" : "S",
          edita_professores: this.fastUsuarioEditar.edita_professores == false ? "N" : "S",
          edita_plataforma: this.fastUsuarioEditar.edita_plataforma == false ? "N" : "S",
          edita_matriz: this.fastUsuarioEditar.edita_matriz == false ? "N" : "S",
          edita_financeiro: this.fastUsuarioEditar.edita_financeiro == false ? "N" : "S",
          edita_alunos: this.fastUsuarioEditar.edita_alunos == false ? "N" : "S",
          edita_admins: this.fastUsuarioEditar.edita_admins == false ? "N" : "S",
        };
        mensagem_sucesso = "Permissões alteradas com sucesso";
      }
      //console.log(this.fastUsuarioEditar);

      this.fastAcao = true;
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        console.log(json);
        if (acao == "R") {
          this.fastLinkConvite = settings.endFastEad + "convite?token=" + json[0].token;
        }
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: mensagem_sucesso,
        });
        this.hideModal("modalEditarPermissoes");
        this.$store.state.fastCarregando = false;

        this.fastUsuarioEditar = {};
      } catch (e) {
        this.fastAcao = false;
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#fastLinkConvite");
      testingCodeToCopy.select();
      testingCodeToCopy.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.fastLinkConviteMsg = "Link copiado com sucesso!";
    },

    exibeModalUsuarioDesativar(usuario) {
      this.fastUsuarioExcluir = usuario;
      this.showModal("modalUsuarioDesativar");
    },
    async confirmaUsuarioDesativar() {
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioExcluir.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "N",
        convite_enviado: "N",
        token: "desativado",
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);

        if (this.fastUsuarioExcluir.administrador == "S") this.getFastUsuariosAdministradores();
        else if (this.fastUsuarioExcluir.professor == "S") this.getFastUsuariosProfessores();
        else this.getFastUsuariosAlunos();

        this.fastUsuarioExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário desativado com sucesso",
        });
        this.hideModal("modalUsuarioDesativar");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalUsuarioAtivar(usuario) {
      this.fastUsuarioExcluir = usuario;
      this.showModal("modalUsuarioAtivar");
    },
    async confirmaUsuarioAtivar() {
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioExcluir.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "S",
        convite_enviado: "S",
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);

        if (this.fastUsuarioExcluir.administrador == "S") this.getFastUsuariosAdministradores();
        else if (this.fastUsuarioExcluir.professor == "S") this.getFastUsuariosProfessores();
        else this.getFastUsuariosAlunos();

        this.fastUsuarioExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário ativado com sucesso",
        });
        this.hideModal("modalUsuarioAtivar");
      } catch (e) {
        console.log("Erro", e);
      }
    },

    // Estatísticas
    async exibeModalEstatisticasUsuario(usuario) {
      this.buscaEstatisticasUsuario(usuario)
        .then((u) => {
          //console.log("buscaEstatisticasUsuario -> then", u);
          const cursos = Array.from(new Set(u.map((a) => a.id_usuario_curso))).map((id_usuario_curso) => {
            return u.find((a) => a.id_usuario_curso === id_usuario_curso);
          });

          cursos.forEach((c, index) => {
            cursos[index].aulas = [];
            u.forEach((a, index2) => {
              if (c.id_usuario_curso == a.id_usuario_curso) {
                let aula_inserida = false;
                cursos[index].aulas.forEach((b) => {
                  if (a.id_usuario_curso_aula == b.id_usuario_curso_aula) {
                    aula_inserida = true;
                  }
                });
                if (!aula_inserida) {
                  cursos[index].aulas.push(a);
                }
                cursos[index].aulas.forEach((b, index2) => {
                  if (!b.atividades) b.atividades = [];
                  if (a.id_usuario_curso_aula == b.id_usuario_curso_aula) {
                    cursos[index].aulas[index2].atividades.push(a);
                  }
                });
              }
            });
          });

          this.fastUsuarioCurso = cursos;
          console.log("this.fastUsuarioCurso", this.fastUsuarioCurso);
          this.showModal("modalEstatisticasUsuario");
        })
        .catch((e) => {
          //console.log("buscaEstatisticasUsuario -> catch", e);
          this.fastUsuarioCurso = [];
          this.showModal("modalEstatisticasUsuario");
        });
    },
    async buscaEstatisticasUsuario(usuario) {
      this.$store.state.fastCarregando = true;
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso/estatisticas_usuario?id_usuario_aluno=" + usuario.id_usuario + "&id_plataforma=" + this.$route.params.id_plataforma + "&id_curso=0", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          this.$store.state.fastCarregando = false;
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          this.$store.state.fastCarregando = false;
          reject(e);
        }
      });
    },
    async geraRelatorioAlunoExcel(obj) {
      if (obj[0]) {
        this.fastCarregandoAcao = true;
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/desempenho_aluno_provas?id_usuario_aluno=" + obj[0].id_usuario + "&id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          console.log("geraRelatorioAlunoExcel", json);
          if (JSON.parse(json).file) {
            var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
            link.href = JSON.parse(json).file;
            link.target = "_blank";
            var event = new MouseEvent("click", {
              view: window,
              bubbles: false,
              cancelable: true,
            });
            link.dispatchEvent(event);
          } else {
            this.exibeToasty("Nenhuma informação encontrada", "info");
          }

          this.fastCarregandoAcao = false;
        } catch (e) {
          console.log("Erro", e);
          this.fastCarregandoAcao = false;
        }
      } else {
        this.exibeToasty("Nenhuma informação encontrada", "info");
      }
    },
    // Cursos
    async getFastCursos() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_plataforma?id_usuario=0" + "&id_plataforma=" + this.retornaSessao(settings.fastSessaoPlataforma).id_plataforma + "&id_curso=", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let objPlataforma = Array.from(json);
        if (objPlataforma.length > 0) {
          this.fastMeusCursos = objPlataforma;
          this.fastMeusCursosFiltro = objPlataforma;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async insereUsuarioRestrito(id_usuario, id_curso) {
      let fast_curso_plataforma = {
        id_curso: id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/insere_usuario_restrito", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma)));
        let json = await resp.json();
        this.getUsuariosRestritos();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário inserido no curso com sucesso",
        });
        this.hideModal("modalMeusCursos");
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async excluiUsuarioRestrito(id_usuario, id_curso) {
      let fast_curso_plataforma = {
        id_curso: id_curso,
        id_usuario_responsavel: id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
      };
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/exclui_usuario_restrito", this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma)));
        let json = await resp.json();
        this.getUsuariosRestritos();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário excluído com sucesso",
        });
        this.hideModal("modalMeusCursos");
        this.$store.state.fastCarregando = false;
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async getUsuariosRestritos(id_usuario) {
      this.$store.state.fastCarregando = true;
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_curso_restrito/lista_usuarios_restritos?id_curso=0&id_plataforma=" + this.$route.params.id_plataforma + "&id_usuario=" + id_usuario, this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          resolve(obj);
        } catch (e) {
          this.$store.state.fastCarregando = false;
          reject(e);
          console.log("Erro", e);
        }
      });
    },
  },
};
</script>

<style></style>
